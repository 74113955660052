import React from 'react'
import { founders } from '../assets/data/founders'
import { advisors } from '../assets/data/advisors'
import { supportedByLogos } from '../assets/data/supportedByLogos'
import TitleBox from '../components/styledComponents/TitleBox'
import WrapperGrid from '../components/styledComponents/WrapperGrid'
import SectionGrid from '../components/styledComponents/SectionGrid'
import SecondaryGrid from '../components/styledComponents/SecondaryGrid'
import StyledDivider from '../components/styledComponents/StyledDivider'
import TopContainer from '../components/styledComponents/TopContainer'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

const sections = [
  { name: 'founders', data: founders },
  { name: 'advisors', data: advisors }
]

const AboutUs = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <WrapperGrid container>
          <Grid item>
            <TitleBox>
              <Typography variant='h3' component='h2' align='center'>
                About Us
              </Typography>
              <StyledDivider variant='middle' />
            </TitleBox>
            <SectionGrid container>
              <Grid item>
                <Typography component='span' variant='h4'>
                  Company
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1'>
                  Our experience with financial markets covers various segments,
                  from designing quantitative trading strategies at BNP Paribas
                  to modeling broker-dealer interactions in OTC CDS markets at
                  Bank of France, among many others.
                  <br /> <br />
                  At Gematria, we promote excellence and diversity. Our R&D team
                  is composed of talents from 7 different countries, holding
                  over 10 medals at national or international levels in computer
                  science or mathematical olympiads and other hackathons, with
                  backgrounds in top institutions from 3 continents.
                </Typography>
              </Grid>
            </SectionGrid>
          </Grid>
          {sections.map((section) => (
            <Grid item key={section.name}>
              <TitleBox>
                <Typography variant='h4' align='center'>
                  {section.name === 'founders' ? 'Founders' : 'Advisors'}
                </Typography>
              </TitleBox>
              <SecondaryGrid container aboutUsSection={true}>
                {section.data.map((el) => (
                  <Grid
                    key={el.name}
                    md={5}
                    item
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    rowSpacing={2}
                    align='center'
                  >
                    <Grid item>
                      <Box
                        alt={el.name}
                        src={el.img}
                        component='img'
                        width='10em'
                      />
                    </Grid>

                    <Grid item>
                      <Typography variant='h6'>{el.name}</Typography>
                      <Typography variant='subtitle2'>{el.title}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='body1' align='justify'>
                        {el.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </SecondaryGrid>
            </Grid>
          ))}
          <Grid item>
            <TitleBox>
              <Typography component='h2' variant='h3' align='center'>
                Supported by
              </Typography>
            </TitleBox>
            <SectionGrid container>
              <Grid item container alignItems='center' justifyContent='center'>
                {supportedByLogos.map((logo, index) => (
                  <Grid key={index} item xs={2.7} align='center'>
                    <Box
                      src={logo}
                      component='img'
                      width='60%'
                      sx={{ borderRadius: '15%' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </SectionGrid>
          </Grid>
        </WrapperGrid>
      </TopContainer>
    </Box>
  )
}

export default AboutUs
