import garyBécigneul from '../images/founders/gary-becigneul.png'
import louisAbraham from '../images/founders/louis-abraham.png'

export const founders = [
  {
    name: 'Dr. Gary Bécigneul',
    title: '(CEO)',
    img: garyBécigneul,
    text: 'Dr. Gary Bécigneul, our CEO, is a former AI researcher at MIT. His AI research is cited by hundreds of scientific papers and published at the most prestigious conferences. He holds a PhD in Machine Learning from ETH Zurich, supervised by Prof. Dr. Thomas Hofmann - former Director of Engineering of Google Zürich. Gary also holds a master in math from Cambridge University, and is the laureate of Max Planck’s Society PhD Prize for being the youngest, at 25, to complete an outstanding thesis across all subjects of the 86 Max Planck Institutes. In his free time, he trains for kickboxing competitions.'
  },
  {
    name: 'Louis Abraham',
    title: '(CTO)',
    img: louisAbraham,
    text: 'Louis Abraham, our CTO, is a computer scientist with a wide array of expertise: he co-founded the cloud security start-up Secrecy, used to be a quant at BNP Paribas London and conducted economic research at Banque de France on OTC CDS markets and AI research at Ecole Normale Superieure. He holds masters in computer science from ETH Zurich and Ecole Polytechnique, where he entered at 17 - most students being 20. Louis also achieved France’s best score in history at the ICPC World Finals - the most prestigious programming contest worldwide. In his free time, he studies orchestral conducting.'
  }
]
