import alexDube from '../images/advisors/alex-dube.png'
import thomasHofmann from '../images/advisors/thomas-hofmann.png'

export const advisors = [
  {
    name: 'Alexandre Dubé',
    title: '(Financial Markets)',
    img: alexDube,
    text: 'Alexandre Dubé is the Head of Financial Markets Trading at Total Energies. He is responsible for FX and Fixed Income risk within the Group. He is also a participant in the FXCG at the European Central Bank.'
  },
  {
    name: 'Prof. Dr. Thomas Hofmann',
    title: '(Engineering)',
    img: thomasHofmann,
    text: 'Prof. Dr. Thomas Hofmann is a Professor of Machine Learning at ETH Zürich. He was the co-founder & CTO of 1plusX - acquired $150M by TripleLift - and Recommind - acquired $163M by OpenText. From 2006 to 2014, he was the Director of Engineering & co-site Lead of Google Zürich.'
  }
]
